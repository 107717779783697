import request from '@/utils/request'


// 查询用户红娘列表
export function listMatchmaker(query) {
  return request({
    url: '/user/user-matchmakers/list',
    method: 'get',
    params: query
  })
}

// 查询用户红娘分页
export function pageMatchmaker(query) {
  return request({
    url: '/user/user-matchmakers/page',
    method: 'get',
    params: query
  })
}

// 查询用户红娘详细
export function getMatchmaker(data) {
  return request({
    url: '/user/user-matchmakers/detail',
    method: 'get',
    params: data
  })
}

// 新增用户红娘
export function addMatchmaker(data) {
  return request({
    url: '/user/user-matchmakers/add',
    method: 'post',
    data: data
  })
}

// 修改用户红娘
export function updateMatchmaker(data) {
  return request({
    url: '/user/user-matchmakers/edit',
    method: 'post',
    data: data
  })
}

// 删除用户红娘
export function delMatchmaker(data) {
  return request({
    url: '/user/user-matchmakers/delete',
    method: 'post',
    data: data
  })
}
export function approveMatchmaker(data) {
  return request({
    url: '/user/user-matchmakers/approveMatchmaker',
    method: 'post',
    data: data
  })
}

